import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyApTrnAWMKuHa2epC2dJrXeoBcu-Cu8-HQ",
  authDomain: "catch-3.firebaseapp.com",
  databaseURL: "https://catch-3-default-rtdb.firebaseio.com",
  projectId: "catch-3",
  storageBucket: "catch-3.appspot.com",
  messagingSenderId: "343265935706",
  appId: "1:343265935706:web:a9579768e1f1fc84f4b363",
  measurementId: "G-1B46QPQWR5"
  });


  const base = Rebase.createClass(firebaseApp.database())

  export { firebaseApp }

  export default base;