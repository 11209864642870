import React from "react";
import PropTypes from 'prop-types';
import { getFunName } from '../helpers';

class StorePicker extends React.Component {
  // constructor(){
  //   super();
  //   this.goToStore = this.goToStore.bind(this)
  // }

  myInput = React.createRef();

  static propTypes = {
    history: PropTypes.object
  }

  // goToStore(event) {
  //   event.preventDefault();
  //   console.log(this)
  // }

  // Version without Constructor, now gotToSTore is a method on StorePicker and bind to the Component

  goToStore = event => {
    event.preventDefault();
    console.log(this.myInput.current.value)
    const storeName = this.myInput.current.value

    this.props.history.push(`/store/${storeName}`)



  }

  render() {
    return (
      <form className="store-selector" onSubmit={this.goToStore}>
        <h2>Please Enter A Store</h2>
        <input 
        type="text" 
        ref={this.myInput}
        required 
        placeholder="Store Name" 
        defaultValue={getFunName()} />
        <button type="submit">Visit Store →</button>
      </form>
    );
  }
}

export default StorePicker;
